<template>
  <div>
    <breadcrumb name="Adds On" title="All Adds On" subtitle="List"/>

    <div id="data-list-list-view" class="data-list-container">

      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @onSuccess="onSuccessOperation" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

      <vs-table
        ref="table"
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        :data="items"
      >

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

          <div class="flex flex-wrap-reverse items-center data-list-btn-container">

            <add-new-button @click="addNewData()" />

          </div>

          <!-- ITEMS PER PAGE -->
          <table-header
            :current-page="currentPage"
            :items-per-page="itemsPerPage"
            :length="items.length"
            :queried-items="queriedItems"
            @onChangeItemsPerPage="itemsPerPage = $event"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="name_en">English Name</vs-th>
          <vs-th sort-key="name_ar">Arabic Name</vs-th>
          <vs-th sort-key="description_en">English Description</vs-th>
          <vs-th sort-key="description_ar">Arabic Description</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <string-row :data="tr.name_en" />
            <string-row :data="tr.name_ar" />
            <string-row :data="tr.description_en" />
            <string-row :data="tr.description_ar" />
            <actions-row
              @onEditClicked="editData(tr)"
              @onDeleteClicked="deleteData(tr.id)"
            />

          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
    <vs-pagination :total="totalItems" v-model="page"></vs-pagination>
  </div>
</template>

<script>
    import DataViewSidebar from './data-view-sidebar';
    import breadcrumb from '../../../../components/general/breadcrumb';
    import TableHeader from "../../../../components/table/table-header";
    import StringRow from "../../../../components/table/rows/string-row";
    import ImageRow from "../../../../components/table/rows/image-row";
    import StatusRow from "../../../../components/table/rows/status-row";
    import ActionsRow from "../../../../components/table/rows/actions-row";
    import AddNewButton from "../../../../components/form/addnew-button";

    export default {
        components: {
            ActionsRow,
            StatusRow,
            ImageRow,
            StringRow,
            TableHeader,
            DataViewSidebar,
            breadcrumb,
            AddNewButton
        },
        data() {
            return {
                items: [],
                itemsPerPage: 20,
                isMounted: false,
                page: 1,
                totalItems:0,
                query: "",
                sortKey: null,
                sortActive: null,

                // Data Sidebar
                addNewDataSidebar: false,
                sidebarData: {},
            }
        },
        computed: {
            currentPage() {
                if(this.isMounted) {
                    return this.$refs.table.currentx
                }
                return 0
            },
            queriedItems() {
                return this.$refs.table ? this.$refs.table.queriedResults.length : this.items.length
            }
        },
        watch: {
            page() {
                this.getData()
            },
            query() {
                this.getData()
            },
            sortKey() {
                this.getData()
            },
            sortActive() {
                this.getData()
            },
            itemsPerPage() {
                this.getData()
            }
        },
        methods: {
            onSuccessOperation() {
                this.getData();
            },
            addNewData() {
                this.sidebarData = {};
                this.toggleDataSidebar(true)
            },
            deleteData(id) {
                this.$vs.loading();
                this.$http
                    .delete(`/adons/${id}`)
                    .then(() => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Successful operation",
                            text: "Adds On has been deleted successfully",
                            color: 'success'
                        });
                        this.getData();
                    })
                    .catch(() => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Something Error",
                            text: "Couldn't delete data on the server",
                            color: 'danger'
                        });
                    })
            },
            editData(data) {
                this.sidebarData = data;
                this.toggleDataSidebar(true)
            },
            toggleDataSidebar(val=false) {
                this.addNewDataSidebar = val
            },
            handleSearch(query) {
                this.query = query
            },
            handleSort(key, active) {
                this.sortKey = key;
                this.sortActive = active === 'asc' ? 1 : 0;
            },
            getData() {
                this.$vs.loading();
                const queryString = `per_page=${this.itemsPerPage}&page=${this.page}&query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}`;
                this.$http
                    .get(`/adons?${queryString}`)
                    .then(res => {
                        this.$vs.loading.close();
                        this.items = res.data.data.data;
                        this.totalItems = Math.ceil(res.data.data.total / this.itemsPerPage);
                    })
                    .catch(() => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Something Error",
                            text: "Couldn't fetch data from the server",
                            color: 'danger'
                        });
                    })
            }
        },
        mounted() {
            this.isMounted = true;
            this.getData()
        }
    }
</script>

<style lang="scss">
</style>
